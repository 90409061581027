import Image from 'next/image'
import { PortableText } from '@portabletext/react'
import { CorporateContactJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import { MdEmail as Email, MdPhone as Phone } from 'react-icons/md'
import Link from 'next/link'
import { FC } from 'react'
import { getImageProps, serializer } from '../lib/sanity'
import { SITE_URL } from '../lib/constants'
import Container from './container'
import { ContactBlockProps } from '../lib/types'
import { useCurrentLocale } from '../lib/utils'

const ContactBlock: FC<ContactBlockProps> = ({
  color = 'blue',
  slug,
  title,
  body,
  additionalText,
  contactPerson,
  sectionClassName = undefined,
  image,
}) => {
  const getColor = {
    blue: 'bg-blue',
    yellow: 'bg-yellow',
    red: 'bg-red',
    white: 'bg-white',
    beige: 'bg-beige',
  }[color]

  const router = useRouter()
  const locale = useCurrentLocale()

  return (
    <section
      className={`${getColor} relative pt-24 transition-background focus:outline-0 dark:bg-zinc-900 lg:min-h-[calc(125vh)] lg:pb-64 ${sectionClassName}`}
    >
      <Container>
        <div className="relative z-20 mt-8 mb-16 space-y-4 break-words rounded-t-2xl rounded-bl-2xl bg-beige px-6 py-12 text-xl transition-background dark:bg-zinc-800 lg:mx-auto lg:max-w-xl lg:px-16">
          <h2
            id={slug}
            tabIndex={-1}
            className="text-4xl font-semibold leading-tight lg:text-6xl"
          >
            {title}
          </h2>
          {body && <PortableText value={body} components={serializer} />}

          <div className="flex flex-wrap pb-3 lg:flex-row">
            <div className="order-2 space-y-3">
              <h3 className="order-2 mt-3 text-xl font-semibold lg:text-2xl">
                {contactPerson.name}
              </h3>
              <Link
                href={`mailto:${contactPerson.email}`}
                className="order-3 mb-4 mt-3 block space-x-2 text-darkblue underline underline-offset-[0.15em] hyphens-auto hover:brightness-95 focus-visible:brightness-95 dark:text-blue lg:text-lg"
              >
                <Email className="inline-flex text-2xl" />
                <span>{contactPerson.email}</span>
              </Link>
              <Link
                href={`tel:${contactPerson.phone.replace(/\s/g, '')}`}
                className="order-4 mb-3 mt-3 block space-x-2 text-darkblue underline underline-offset-[0.15em] hyphens-auto hover:brightness-95 focus-visible:brightness-95 dark:text-blue lg:text-lg"
              >
                <Phone className="inline-flex text-2xl" />
                <span>{contactPerson.phone}</span>
              </Link>
            </div>
            <div className="relative mr-4">
              {contactPerson.image &&
              contactPerson.imageUrl &&
              contactPerson.image.alt ? (
                <Image
                  className="order-1"
                  src={contactPerson.imageUrl}
                  alt={contactPerson.image.alt || ''}
                  width={187}
                  height={200}
                />
              ) : (
                <div className="order-1 h-[200px] w-[200px] rounded-full bg-blue" />
              )}
            </div>
          </div>
          {additionalText && (
            <PortableText value={additionalText} components={serializer} />
          )}
        </div>
        {image?.rawimage && (
          <picture>
            {image.responsiveCrop && (
              <source
                media="(max-width: 991px)"
                {...getImageProps({
                  image: image.rawimage,
                  isSquare: image.responsiveCrop,
                  maxWidth: '100vw',
                })}
              />
            )}
            <img
              {...getImageProps({
                image: image.rawimage,
                maxWidth: 2500,
              })}
              alt="Post"
              loading="lazy"
              className="left-0 bottom-0 z-10 w-full scale-x-[-1] object-contain dark:brightness-75 lg:absolute"
            />
          </picture>
        )}
      </Container>
      <CorporateContactJsonLd
        url={`${SITE_URL}${locale !== router.defaultLocale ? locale : ''}`}
        contactPoint={[
          {
            telephone: contactPerson.phone.replace(/\s/g, ''),
            email: contactPerson.email,
            contactType: 'sales',
            availableLanguage: ['English', 'Swedish'],
          },
        ]}
      />
    </section>
  )
}
export default ContactBlock
