import { PortableText } from '@portabletext/react'
import { useRouter } from 'next/router'
import { FC } from 'react'
import Container from './container'
import {
  useCurrentLocale,
  usePreferredTheme,
  useTranslation,
} from '../lib/utils'
import { serializer, getImageProps } from '../lib/sanity'
import Button from './button'
import LinkCard from './link-card'
import { ContentSectionProps, ServiceProps } from '../lib/types'
import { badContent } from '../lib/badUtils'

interface FeaturedServicesSectionProps extends ContentSectionProps {
  wider?: boolean
  allFeaturedServices?: Array<ServiceProps>
  isBad?: boolean
}
const FeaturedServicesSection: FC<FeaturedServicesSectionProps> = ({
  heading,
  content,
  image,
  color = 'white',
  orientation,
  cta,
  isFirst,
  slug,
  darkImage,
  wider,
  useProse,
  allFeaturedServices,
  isBad,
}) => {
  const { preferredTheme } = usePreferredTheme()
  const router = useRouter()
  const locale = useCurrentLocale()
  const t = useTranslation()
  const hasDarkImage = darkImage !== undefined
  const firstThreeServices = allFeaturedServices?.slice(0, 3)

  const getBadContent = (index: number) => {
    if (index === 0)
      return (
        <p>
          {badContent.startPage[locale].strategicContent}
        </p>
      )
    if (index === 1)
      return (
        <p>
          {badContent.startPage[locale].consultantsContent}
        </p>
      )
    if (index === 2)
      return (
        <p>
          {badContent.startPage[locale].analysesContent}
        </p>
      )

    return null
  }

  const getColor = {
    blue: 'bg-blue',
    yellow: 'bg-yellow',
    red: 'bg-red',
    white: 'bg-white',
    green: 'bg-green',
    beige: 'bg-beige',
  }[color]

  const badFeaturedServicesContent = badContent.startPage[locale]

  const getBadServicesHeading = (index: number) => {
    if (index === 0) return badFeaturedServicesContent.strategicHeading
    if (index === 1) return badFeaturedServicesContent.consultantsHeading
    if (index === 2)
      return badFeaturedServicesContent.analysesHeading
    return <p />
  }

  return (
    <section
      className={`${getColor} relative mt-[-1px] ${
        isFirst ? 'pt-2 lg:pt-8' : 'pt-8 lg:pb-8'
      } transition-background focus:outline-0 dark:bg-zinc-900 lg:min-h-[calc(80vh)]`}
    >
      <Container>
        <div
          className={`${
            useProse
              ? 'prose-lg prose prose-neutral dark:prose-invert'
              : 'space-y-3'
          } my-8 break-words rounded-t-2xl rounded-bl-2xl bg-beige py-8 px-6 text-xl transition-background dark:bg-zinc-800 lg:py-12 lg:px-12 ${
            orientation === 'left'
              ? 'mx-auto xl:ml-auto xl:mr-[initial]'
              : 'mx-auto xl:mr-auto xl:ml-[initial]'
          } relative z-20 mb-8 ${wider ? 'lg:max-w-xl' : 'lg:max-w-xl '}`}
        >
          <h2
            id={slug}
            tabIndex={-1}
            className="text-4xl font-semibold leading-tight focus:outline-0 focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-darkblue dark:focus-visible:outline-white lg:text-4xl"
          >
            {heading}
          </h2>
          {content && <PortableText value={content} components={serializer} />}
        </div>

        {firstThreeServices && (
          <div className="mx-auto grid max-w-xl grid-cols-1 gap-4 xl:mx-[initial]">
            {firstThreeServices.map((service, index) => (
              <LinkCard
                color="lightblue"
                headingLevel={isBad ? 'h5' : 'h3'}
                key={service.puffTitle}
                href={`${cta?.target}#${service.slug}`}
                // @ts-ignore
                title={getBadServicesHeading(index)}
              >
                {getBadContent(index)}
                <p
                  className="mt-2 underline underline-offset-[0.15em] lg:hidden"
                  aria-hidden
                >{`${t.readMore}`}</p>
              </LinkCard>
            ))}
            {cta?.label && cta?.target && (
              <div className="mb-24 justify-self-center xl:mb-0">
                <Button
                  href={`/${
                    locale !== router.defaultLocale
                      ? `${locale}/${cta.target}`
                      : cta.target
                  }`}
                  className="mt-4 self-center"
                >
                  {cta?.label}
                </Button>
              </div>
            )}
          </div>
        )}
      </Container>
      {image?.rawimage && (
        <picture>
          {image.responsiveCrop && (
            <source
              media="(max-width: 991px)"
              {...getImageProps({
                image: image.rawimage,
                isSquare: image.responsiveCrop,
                maxWidth: '100vw',
              })}
            />
          )}
          <img
            {...getImageProps({
              image: image.rawimage,
              maxWidth: 2500,
            })}
            loading="lazy"
            alt={image.alt || ''}
            className={`${
              preferredTheme === 'dark' && hasDarkImage ? 'dark:hidden' : ''
            } z-10 w-full object-contain object-right dark:brightness-75 xl:absolute ${
              isFirst ? '' : 'xl:h-[calc(80vh+40px)]'
            } ${isFirst ? 'bottom-0' : 'lg:top-[-40px]'} ${
              orientation === 'left' ? 'left-0' : 'right-0'
            }`}
          />
        </picture>
      )}
    </section>
  )
}

export default FeaturedServicesSection
