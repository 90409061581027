import Link from 'next/link'
import { localizedPaths, useCurrentLocale, useTranslation } from '../lib/utils'

const NewsletterLink = ({ inline = false }) => {
  const t = useTranslation()
  const locale = useCurrentLocale()

  const tailwindClasses = inline
    ? ''
    : 'space-3 mt-16 flex flex-col text-center'

  const Tag = inline ? 'span' : 'h3'
  const ContainerTag = inline ? 'p' : 'div'

  return (
    <ContainerTag className={tailwindClasses}>
      <Tag
        className={`${
          inline ? 'mr-2 inline-block' : 'mb-5 text-3xl font-bold'
        }`}
      >
        {`${t.doYouWantToReadMore}`}
      </Tag>
      <Link
        href={`/${localizedPaths(locale).newsletter}`}
        className={`${
          inline ? '' : 'mx-auto'
        } w-fit underline underline-offset-[0.2em] hover:brightness-90`}
      >
        {!inline ? <p>{t.signupForNewsletter}</p> : t.signupForNewsletter}
      </Link>
    </ContainerTag>
  )
}

export default NewsletterLink
