import { useRouter } from 'next/router'
import { FC } from 'react'
import Container from './container'
import PostPuff from './post-puff'
import TextBox from './text-box'
import Button from './button'

import { localizedPaths, useCurrentLocale, useTranslation } from '../lib/utils'
import { PostPuffProps } from '../lib/types'
import NewsletterLink from './newsletterLink'

interface StartPageNewsProps {
  allPosts: Array<PostPuffProps>
  showNewsletter?: boolean
  isBad?: boolean
}
const StartPageNews: FC<StartPageNewsProps> = ({
  allPosts,
  showNewsletter = true,
  isBad = false,
}) => {
  const router = useRouter()
  const locale = useCurrentLocale()

  // const lastPublished = allPosts[0].date || new Date().toDateString()
  // Get the first featured article
  const featuredArticle = allPosts.find((post) => post.isFeatured)
  // Get all other articles except the first featured one
  const otherArticles = allPosts.filter((post) => post !== featuredArticle)

  const t = useTranslation()

  return (
    <section className="focus-within: relative bg-white pt-8 pb-32 transition-background focus:outline-0 dark:bg-zinc-900 lg:pb-44 xl:pb-64">
      <Container>
        <TextBox className="lg:text-center" color="transparent">
          <h2
            tabIndex={-1}
            id="aktuellt"
            className="text-4xl font-semibold leading-tight focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-darkblue focus-visible:brightness-95 dark:focus-visible:outline-white lg:text-6xl"
          >{`${t.current}`}</h2>
        </TextBox>
        <div className="grid gap-8 lg:mx-auto lg:max-w-screen-xl lg:grid-cols-2">
          {featuredArticle && (
            <PostPuff post={featuredArticle} size="large" isBad={isBad} />
          )}
          {!isBad &&
            otherArticles?.map((post) => (
              <PostPuff
                /* eslint-disable-next-line no-underscore-dangle */
                key={post._id}
                post={post}
                isBad={isBad}
              />
            ))}
        </div>
        <div className="mb-24 flex flex-col justify-center xl:mb-0">
          <Button
            href={`/${
              locale !== router.defaultLocale
                ? `${locale}/${localizedPaths(locale).posts}`
                : localizedPaths(locale).posts
            }`}
            className="mx-auto mt-8 w-fit"
          >
            {t.showAllArticles}
          </Button>
          {showNewsletter && <NewsletterLink />}
        </div>
      </Container>
    </section>
  )
}

export default StartPageNews
