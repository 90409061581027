import { NextSeo } from 'next-seo'
import React, { FC } from 'react'
import {
  getAllPostsPuffs,
  getAllStaff,
  getAllUSPs,
  getContactData,
  getFeaturedServicesData,
  getHeroSection,
  getMenuItems,
  getSiteData,
  newsLetterExists,
} from '../lib/api'
import Layout from '../components/layout'
import Container from '../components/container'
import Intro from '../components/intro'
import ContentSection from '../components/content-section'
import ContactBlock from '../components/contact-block'
import Footer from '../components/footer'
import StartPageNews from '../components/start-page-news'
import { baseLanguageURLs, useCurrentLocale } from '../lib/utils'
import FeaturedServicesSection from '../components/featured-services-section'
import generateRssFeed from '../lib/createRssFeed'
import {
  ContactBlockProps,
  HeroSectionProps,
  SiteDataProps,
  ContentSectionProps,
  countriesType,
  ServiceProps,
  PostPuffProps,
} from '../lib/types'
import { badContent } from '../lib/badUtils'
// import Snowfall from '../components/snowfall'

export interface IndexProps {
  allPosts?: Array<PostPuffProps>
  siteData: SiteDataProps
  contactData: ContactBlockProps
  allMenuItems: Array<{ title: string; target: string }>
  heroSection: HeroSectionProps
  allSections: Array<ServiceProps>
  featuredServicesSection?: ContentSectionProps

  showNewsletter: boolean
  isBadWebsite: boolean
}
const Index: FC<IndexProps> = ({
  allPosts,
  siteData,
  contactData,
  allMenuItems,
  heroSection,
  allSections,
  showNewsletter,
  featuredServicesSection,
  isBadWebsite = false,
}) => {
  const locale = useCurrentLocale()


  const allFeaturedUSPs = allSections?.filter(
    (section) => section?.puffTitle && section?.puffTitle?.length > 0
  )
  const badStartPageContent = badContent.startPage[locale]

  return (
    <Layout isBad={isBadWebsite} showBadDisclaimer>
      {/* <Snowfall /> */}
      <NextSeo
        title="Uselt"
        description={
          isBadWebsite ? undefined : siteData?.meta?.description[locale]
        }
        canonical={baseLanguageURLs(locale)?.replace(/\/+$/, '')}
        languageAlternates={process.env.LOCALES?.split(',').map((language) => ({
          hrefLang: language,
          href: `${baseLanguageURLs(language as countriesType)?.replace(
            /\/+$/,
            ''
          )}`,
        }))}
      />
      <header className="bg-blue transition-background dark:bg-zinc-900">
        <Container className="pt-8">
          <Intro allMenuItems={allMenuItems} isBad={isBadWebsite} />
        </Container>
      </header>
      <main>
        {heroSection && (
          <ContentSection
            headingLevel="h1"
            isStartPage
            isFirst
            isBad={isBadWebsite}
            heading={badStartPageContent.heading}
            badTextContent={<p>{badContent.startPage[locale].intro}</p>}
            color={heroSection.color ? heroSection.color : undefined}
            image={{
              alt: isBadWebsite ? badStartPageContent.imageAlt : heroSection.image?.alt,
              url: heroSection.image?.url,
              rawimage: heroSection.image?.rawimage,
              responsiveCrop: heroSection.image?.responsiveCrop,
            }}
            darkImage={{
              alt: isBadWebsite ? badStartPageContent.imageAlt : heroSection.darkImage?.alt,
              url: heroSection.darkImage?.url,
              rawimage: heroSection.darkImage?.rawimage,
              responsiveCrop: heroSection.darkImage?.responsiveCrop,
            }}
            cta={heroSection.cta ? heroSection.cta : undefined}
            orientation={heroSection.orientation}
            slug={heroSection.slug}
            classes="xl:max-h-[950px]"
            useProse={false}
            title={heroSection.title}
          />
        )}
        {allFeaturedUSPs?.length > 0 && featuredServicesSection && (
          <FeaturedServicesSection
            heading={featuredServicesSection.title}
            allFeaturedServices={allFeaturedUSPs}
            cta={featuredServicesSection.cta}
            content={featuredServicesSection.body}
            color={featuredServicesSection.color}
            slug={featuredServicesSection.slug}
            orientation={featuredServicesSection.orientation}
            image={{
              alt: isBadWebsite ? badStartPageContent.karate : featuredServicesSection.image?.alt,
              url: featuredServicesSection.image?.url,
              rawimage: featuredServicesSection.image?.rawimage,
              responsiveCrop: featuredServicesSection.image?.responsiveCrop,
            }}
            title={featuredServicesSection.title}
            isBad={isBadWebsite}
          />
        )}

        {allPosts && allPosts?.length > 0 && (
          <StartPageNews
            allPosts={allPosts}
            showNewsletter={showNewsletter}
            isBad={isBadWebsite}
          />
        )}

        {contactData && (
          <ContactBlock
            image={{
              alt: isBadWebsite ? badStartPageContent.letter : contactData?.image?.alt,
              url: contactData?.image?.url,
              rawimage: contactData?.image?.rawimage,
              responsiveCrop: contactData?.image?.responsiveCrop,
            }}
            color={contactData.color}
            slug={contactData.slug}
            title={contactData.title}
            contactPerson={contactData.contactPerson}
            body={contactData.body}
            additionalText={contactData.additionalText}
            sectionClassName="lg:pt-16 before:absolute before:top-[-5vw] before:left-0 before:right-0 before:block before:h-[50%] before:skew-y-[2deg] before:bg-inherit before:content-['']"
            /* eslint-disable-next-line no-underscore-dangle */
            _id={contactData._id}
          />
        )}
      </main>
      <Footer
        siteData={siteData}
        showNewsletter={showNewsletter}
        isBad={isBadWebsite}
      />
    </Layout>
  )
}

export async function getStaticProps({ locale }: { locale: countriesType }) {
  const isBadWebsite = process.env.IS_BAD_WEBSITE === 'true'
  const allPosts = await getAllPostsPuffs(false, locale)
  const allSections = await getAllUSPs(false, locale)
  const siteData = await getSiteData(false)
  const contactData = await getContactData(
    false,
    locale !== 'sv' ? locale : undefined
  )
  const heroSection = await getHeroSection(
    false,
    locale !== 'sv' ? locale : undefined
  )

  const showNewsletter = await newsLetterExists(
    locale !== 'sv' ? locale : undefined
  )

  const featuredServicesSection = await getFeaturedServicesData(
    false,
    locale !== 'sv' ? locale : undefined
  )
  const allStaff = await getAllStaff(false)
  /* const allCustomers = await getAllCustomers(
    false,
    locale !== 'sv' ? locale : undefined
  ) */
  const allMenuItems = await getMenuItems(false, locale)

  await generateRssFeed(locale, siteData)

  return {
    props: {
      allPosts,
      allSections,
      siteData,
      heroSection,
      allStaff,
      featuredServicesSection,
      showNewsletter,
      isBadWebsite,
      // allCustomers,
      allMenuItems,
      contactData,
      preview: false,
    },
  }
}
export default Index
