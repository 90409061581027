import React, { FC } from 'react'
import { PortableText } from '@portabletext/react'
import Container from './container'
import Button from './button'
import { baseLanguageURLs, useCurrentLocale, usePreferredTheme, useTranslation } from '../lib/utils'
import { serializer, getImageProps } from '../lib/sanity'
import TextBox from './text-box'
import { ContentSectionProps } from '../lib/types'
import { badContent } from '../lib/badUtils'

const ContentSection: FC<ContentSectionProps> = ({
  headingLevel = 'h2',
  heading,
  content,
  image,
  color = 'blue',
  orientation,
  cta,
  isFirst = false,
  slug,
  classes = undefined,
  darkImage,
  useProse = false,
  isStartPage = false,
  offsetImage = false,
  isBad = false,
  isBadHideCTA = false,
  badTextContent = undefined,
  index,
}) => {
  const getColor = {
    blue: 'bg-blue',
    yellow: 'bg-yellow',
    red: 'bg-red',
    white: 'bg-white',
    green: 'bg-green',
    beige: 'bg-beige',
  }[color]

  const { preferredTheme } = usePreferredTheme()

  const headingWithLineBreaks = heading.split('   ')

  const hasDarkImage = darkImage !== undefined

  const Title = isBad ? 'p' : headingLevel
  const t = useTranslation()
  const locale = useCurrentLocale()

  const getCtaLabel = () => {
    if (isStartPage) return badContent.startPage[locale].cta
    if (index === 0) return badContent.servicesPage[locale].strategicCta
    if (index === 1) return badContent.servicesPage[locale].consultantsCta
    if (index === 2) return badContent.servicesPage[locale].analysisCta
    return ""
  }

  const getBadServicesHeading = () => {
    if (index === 0) return badContent.servicesPage[locale].strategicHeading
    if (index === 1) return badContent.servicesPage[locale].consultantsHeading
    if (index === 2)
      return badContent.servicesPage[locale].analysisHeading
    return <p />
  }

  const getBadServicesText = () => {
    if (index === 0)
      return (
        <>
          <p>
            {badContent.servicesPage[locale].strategicParapraph1}
          </p>
          <p>
          {badContent.servicesPage[locale].strategicParagraph2}
          </p>
        </>
      )
    if (index === 1)
      return (
    <>
    <p>
          {badContent.servicesPage[locale].consultantsParagraph1}
        </p>
        <p>
        {badContent.servicesPage[locale].consultantsParagraph2}
      </p>
    </>
        
      )
    if (index === 2)
      return (
        <>
          <p>
            {badContent.servicesPage[locale].analysisParagraph1}
          </p>
          <ul>
            <li>
              {badContent.servicesPage[locale].analysisParagraph2}
            </li>
            <li>{badContent.servicesPage[locale].analysisParagraph3}</li>
            <li>
            {badContent.servicesPage[locale].analysisParagraph4}
            </li>
          </ul>
        </>
      )
    return <p />
  }

  return (
    <section
      className={`${getColor} relative mt-[-1px] transition-background ${
        isFirst ? 'pt-2 xl:pt-8' : 'pt-8 xl:pb-8'
      } focus:outline-0 dark:bg-zinc-900 lg:min-h-[calc(90vh)]`}
    >
      <Container>
        <TextBox
          orientation={orientation}
          useProse={useProse}
          color={isFirst ? 'white' : 'beige'}
          className="py-12"
        >
          <Title
            id={slug}
            tabIndex={isBad ? undefined : -1}
            className={`${isStartPage ? "start-page-heading" : ""} text-3xl font-semibold leading-tight focus:outline-0 focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-darkblue dark:focus-visible:outline-white lg:text-4xl`}
          >
            {isStartPage && (
              <span className="sr-only">{`${t.companyName} – `}</span>
            )}
            {/*  Hacky solution to insert soft hyphen when editors add triple space  */}
            {index === undefined &&
              heading
                .split('   ')
                .map((substring, i) =>
                  headingWithLineBreaks.length > 1 && i > 0 ? (
                    <React.Fragment key={`${substring}-${i}`}>
                      &shy;{substring}
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={`${substring}-${i}`}>
                      {substring}
                    </React.Fragment>
                  )
                )}
            {index !== undefined && getBadServicesHeading()}
          </Title>
          {getBadServicesText()}
          {badTextContent && badTextContent}
          {content && !isBad && (
            <PortableText value={content} components={serializer} />
          )}
          {cta && cta?.label && cta.target && (
            <>
              <div className="pb-8" />
              <Button
                isBadNoSemantics={isBadHideCTA}
                href={`${baseLanguageURLs(locale)}${cta?.target}`}
                ariaLabel={isBad ? badContent.startPage[locale].badCtaAriaLabel : undefined}
                className={`${
                  isBad ? '!bg-darkblue' : ''
                } !margin-0 absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] md:left-auto md:right-[48px] md:translate-x-0`}
              >
                {getCtaLabel()}
              </Button>
            </>
          )}
        </TextBox>
      </Container>
      {image?.rawimage && (
        <picture>
          {image.responsiveCrop && (
            <source
              media="(max-width: 991px)"
              {...getImageProps({
                image: image.rawimage,
                isSquare: image.responsiveCrop,
                maxWidth: '100vw',
              })}
            />
          )}
          <img
            {...getImageProps({
              image: image.rawimage,
              maxWidth: 2500,
            })}
            alt={image.alt || ''}
            loading={isFirst ? undefined : 'lazy'}
            className={`${
              preferredTheme === 'dark' && hasDarkImage ? 'dark:hidden' : ''
            } ${
              offsetImage ? 'left-[175px]' : ''
            } bottom-0 z-10 w-full object-contain dark:brightness-75 xl:absolute ${
              isFirst ? '' : 'xl:h-[calc(80vh+40px)]'
            } ${
              orientation === 'left'
                ? 'object-left-bottom'
                : 'object-right-bottom'
            } ${classes || ''}`}
          />
        </picture>
      )}
      {darkImage && (
        <picture>
          {darkImage?.responsiveCrop && darkImage?.rawimage && (
            <source
              media="(max-width: 991px)"
              {...getImageProps({
                image: darkImage.rawimage,
                isSquare: darkImage.responsiveCrop,
                maxWidth: '100vw',
              })}
            />
          )}
          {darkImage.rawimage && (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              {...getImageProps({
                image: darkImage.rawimage,
                maxWidth: 2500,
              })}
              alt={darkImage?.alt || ''}
              loading={isFirst ? undefined : 'lazy'}
              className={`z-10 hidden w-full object-contain dark:block dark:brightness-75 xl:absolute ${
                isFirst ? '' : 'xl:h-[calc(80vh+40px)]'
              } ${offsetImage ? 'left-[175px]' : ''} ${
                isFirst ? 'bottom-0' : 'xl:top-[-40px]'
              } ${orientation === 'left' ? 'left-0' : 'right-0'} ${
                classes || ''
              }`}
            />
          )}
        </picture>
      )}
    </section>
  )
}
export default ContentSection
