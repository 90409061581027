/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { MouseEventHandler, useRef } from 'react'

interface LinkCardProps {
  className?: string
  href: string
  title: string
  children?: React.ReactNode
  color?: 'lightblue' | 'beige' | 'red'
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  cardFooter?: React.ReactNode
  hasFooterImage?: boolean
}

const LinkCard: React.FC<LinkCardProps> = ({
  className,
  href,
  title,
  children,
  color = 'beige',
  headingLevel = process.env.IS_BAD_WEBSITE === 'true' ? 'h5' : 'h3',
  hasFooterImage = false,
  cardFooter,
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const cardColor = {
    beige: 'bg-beige',
    lightblue: 'bg-lightblue',
    red: 'bg-red',
  }[color]

  // eslint-disable-next-line consistent-return
  const handleCardClick: MouseEventHandler<HTMLDivElement> = (event): void => {
    if (
      linkRef?.current !== undefined &&
      // @ts-ignore
      !linkRef?.current?.contains(event.target)
    ) {
      // @ts-ignore
      return linkRef?.current?.click()
    }
    // Add your code here to handle the click event on the card element
  }

  const Heading = headingLevel

  // const headingWithLineBreaks = title?.split('   ')

  return (
    <div
      onClick={handleCardClick}
      className={`${
        hasFooterImage ? 'flex flex-col justify-between' : ''
      } group relative z-20 flex cursor-pointer flex-col justify-between space-y-3 rounded-t-2xl rounded-bl-2xl ${cardColor} p-6 transition-background dark:bg-zinc-800 dark:focus-within:outline-white ${
        className || ''
      }`}
    >
      <div>
        <a
          href={href}
          ref={linkRef}
          className="block w-[fit-content] focus-visible:outline-8 focus-visible:outline-darkerblue"
        >
          <Heading className="mb-3 max-w-[65ch] text-xl font-semibold underline-offset-[0.15em] group-hover:underline lg:text-2xl">
            {title}
          </Heading>
        </a>
        {children}
      </div>

      {cardFooter}
    </div>
  )
}
export default LinkCard
