import React from 'react'
import { PortableText } from '@portabletext/react'
import Image from 'next/image'
import { format } from 'date-fns'
import { enGB, nb, sv } from 'date-fns/locale'
import { baseLanguageURLs, localizedPaths, useCurrentLocale, useTranslation } from '../lib/utils'
import { serializer } from '../lib/sanity'
import LinkCard from './link-card'
import { urlForSvg } from '../lib/api'
import { PostProps } from '../lib/types'
import {badContent} from '../lib/badUtils';

interface PostPuffProps {
  post: PostProps
  disableDynamicSize?: boolean
  variant?: 'standard' | 'short'
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  size?: 'small' | 'large'
  isBad?: boolean
}

const PostPuff: React.FC<PostPuffProps> = ({
  post,
  disableDynamicSize = false,
  variant = 'standard',
  size = 'small',
  isBad = false,
  headingLevel = isBad ? 'h5' : 'h3',
}) => {
  const locale = useCurrentLocale()
  const t = useTranslation()
  const getLocale = () =>
    ({
      en: enGB,
      sv,
      no: nb,
    }[locale])

  return (
    <LinkCard
      title={post.title}
      href={`${baseLanguageURLs(post.language)}${
        localizedPaths(post.language).posts
      }/${post.slug}`}
      headingLevel={headingLevel}
      hasFooterImage={!!post.author}
      cardFooter={
        post.author && post.type === 'article' ? (
          <div className="relative flex flex-row flex-wrap items-center gap-5 pt-3">
            {post.author?.illustration ? (
              <Image
                src={urlForSvg(post.author?.illustration?.asset).url()}
                alt=""
                width={141}
                height={150}
                className="h-auto"
              />
            ) : null}
            <div>
              <p className="text-lg font-semibold lg:text-xl">
                <span className="sr-only">{`${t.author}:  `}</span>
                {post.author.title}
              </p>
              {post.author.role && (
                <p className="mb-2 text-base lg:text-lg">
                  {badContent.aboutPage[locale].role}
                </p>
              )}
              {post.date && (
                <p className="text-base opacity-70">
                  {`${t.published} ${format(
                    new Date(post.date),
                    'd MMMM yyyy',
                    {
                      locale: getLocale(),
                    }
                  )}`}
                </p>
              )}
              {post.readingTime && (
                <p className="mt-1 text-base opacity-70">
                  {`${t.readingTime} ${post.readingTime.estimatedReadingTime} ${t.minutes}`}
                </p>
              )}
            </div>
          </div>
        ) : (
          post.date && (
            <p className="text-lg opacity-70">
              {`${t.published} ${format(new Date(post.date), 'd MMMM yyyy', {
                locale: getLocale(),
              })}`}
            </p>
          )
        )
      }
      className={`${
        size === 'large' && !disableDynamicSize ? 'lg:col-span-2' : ''
      }`}
    >
      <div className="max-w-[65ch]">
        {variant !== 'short' && post.summary && (
          <PortableText value={post.summary} components={serializer} />
        )}
        {isBad ? (
          <a
            href={`/${localizedPaths(locale).posts}/${post.slug}`}
            className="mt-8 underline underline-offset-[0.15em] lg:text-lg"
          >{`${t.readMore}`}</a>
        ) : (
          <p
            className="mt-2 underline underline-offset-[0.15em] lg:hidden"
            aria-hidden
          >{`${t.readMore}`}</p>
        )}
      </div>
    </LinkCard>
  )
}
export default PostPuff
